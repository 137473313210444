/*eslint-disable*/
import React , {useEffect } from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import config from "../../config/config"
import { type WalletClient, useWalletClient,useDisconnect } from 'wagmi'
import { useConnect } from 'wagmi';
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "../../reducers/Actions";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import network from "../../config/network";

// import { Link } from 'react-scroll'
import { AppBar, Toolbar, IconButton, Hidden, Drawer, Button,} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect();
  const { disconnect } = useDisconnect()
  var chaindetails = localStorage.getItem("network") ?( network.find((o) =>o.name == (localStorage.getItem("network")) ? o.chainid : config.defaultchainid)): config.defaultchainid;
  let chainid = chaindetails && chaindetails.name ? chaindetails.chainid :  chaindetails; 
  const { data: walletClient } = useWalletClient({ chainid })
  const walletConnection = useSelector((state) => state.walletConnection)
  const dispatch = useDispatch()

  useEffect(() => {
     checkConnect()
  }, [walletClient]);

  function checkConnect(){
    console.log(walletClient,"====walletClient")
    if(walletClient){
      const { account, chain, transport } = walletClient
      console.log(account, chain, transport,"====account, chain, transport")
        dispatch(setWallet({
          connect : "yes",
          web3 : transport
        }));
    }
  }

  return (
    <div className="home_page_menu">      
      <List>
      <ListItem className={classes.listItem}>
        {(!localStorage.getItem("address") || localStorage.getItem("address")==null || localStorage.getItem("address")==undefined || localStorage.getItem("address")=="") ?
          <Button className="primary_btn gradient-box " data-toggle="modal" data-target="#connect_model"><Link  >Connect Wallet</Link></Button> 
          : 
          <Button className="primary_btn gradient-box "><Link>{localStorage.getItem('address')}</Link></Button>
        }
        </ListItem>
      </List>
    </div>
  );
}
