/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <>
    <footer className={footerClasses + " footer_home"}>
      
      <div className={classes.container}>
      
      <div className="row py-3">
            <div className="col-md-12 col-lg-6 mb-3 mb-md-0">
             <ul className="footer_link_ww">
      
               <li><Link to="/about">About us </Link></li>  <i>|</i>
               <li><Link to="/blog">Blog </Link></li>  <i>|</i>
               <li><Link to="/faq">FAQ's </Link></li>  <i>|</i>
               <li><Link to="/terms">Terms & Conditions</Link></li>  <i>|</i>
               <li><Link to="/privacy-policy">Privacy Policy</Link></li> 
             </ul>
              <p>Copyright © 2021<span className="color_grssn"> Swap,</span> All rights reserved</p>
            </div>
            <div className="col-md-12 col-lg-6 mb-3 mb-md-0">
             <div className="flex_footer_1">
             <h5>Social Media withus:</h5>
            <ul className="list-inline d-flex social_media">
              <li className=""><Link  target="_blank"><i className="fab fa-telegram-plane"></i></Link></li>
              <li className=""><Link  target="_blank"><i className="fab fa-twitter"></i></Link></li>
              <li className=""><Link  target="_blank"><i className="fab fa-linkedin-in"></i></Link></li>
              <li className=""><Link  target="_blank"><i className="fab fa-reddit-alien"></i></Link></li>
              <li><Link  target="_blank"><i className="fab fa-youtube"></i></Link></li>
            </ul>
             </div>
            </div>
          </div>
      </div>
    </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
