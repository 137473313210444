import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks";
import styled from "styled-components";
import syncEach from "sync-each";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import WalletModal from "components/WalletModal.js";
import network from "../config/network";
import Web3 from "web3";
import abi from "../ABI/bridgeabi";
import tokenabi from "../ABI/Binancetoken";
import { toastAlert } from "../config/toastAlert";
import config from "../config/config";
import {
  type WalletClient,
  useWalletClient,
  useDisconnect,
  useSwitchNetwork,
} from "wagmi";
import store from "../store";
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "../reducers/Actions";
import transferabi from "../ABI/transferabi";

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

// Trade History Table
const table_data = [];

const table_columns = [
  {
    name: "Date",
    selector: "date",
  },
  {
    name: "Action",
    selector: "action",
    width: "300px",
  },
  {
    name: "Hash",
    selector: "hash",
  },
  {
    name: "Status",
    selector: "status",
  },
];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { disconnect } = useDisconnect();

  const {
    chains,
    error,
    isLoading,
    pendingChainId,
    switchNetwork,
  } = useSwitchNetwork();
  const { ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const timerRef = useRef(null);
  const [networkdetail, setnetworkdetail] = React.useState({});
  let _to = localStorage.getItem("tonetwork") ? localStorage.getItem("tonetwork") : "AVAX" ;
  const [toselected, settoselected] = React.useState(_to);
  const [fromselected, setfromselected] = React.useState("BNB");
  const [fromlogo, setfromlogo] = React.useState("bsc.png");
  const [tologo, settologo] = React.useState("aval.png");
  const [walletaddress, setwalletaddress] = React.useState("");
  const [Destinationtoken, setDestinationtoken] = React.useState("Polygon");
  const [amount, setamount] = React.useState(0);
  const [Destinationtokenaddress, setDestinationtokenaddress] = React.useState(
    ""
  );
  const [targettokenaddress, settargettokenaddress] = React.useState("");
  const [progress, setprogress] = React.useState(false);
  const [status, setstatus] = React.useState("Processing");
  const [servicefee, setsericefee] = React.useState(0);
  var chaindetails = localStorage.getItem("network") ?( network.find((o) =>o.name == (localStorage.getItem("network")) ? o.chainid : config.defaultchainid )): config.defaultchainid;
  let chainid = chaindetails && chaindetails.name ? chaindetails.chainid :  chaindetails; 
  let { data: walletClient } = useWalletClient({ chainid });
  const dispatch = useDispatch();
  const [userbalance, setuserbalance] = useState(0);
  const [usertobalance, setusertobalance] = useState(0);
  const [tokenPrice,settokenPrice]= useState([0,0,0,0,0]);
  const [bnbprogress,setbnbprogress]= React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    checkConnect();
    getnetwork();
    const interval = setInterval(() => {
      getbalance();
      gettokenPrice();
    }, 1000);
    //Clearing the interval
    return () => clearInterval(interval);
  }, [walletClient]);

  function checkConnect() {
    console.log(walletClient, "====walletClient");
    if (walletClient) {
      const { account, chain, transport } = walletClient;
      console.log(account, chain, transport, "====account, chain, transport");
      dispatch(
        setWallet({
          connect: "yes",
          web3: transport,
        })
      );
    }
    getInit();
  }

  async function getInit() {
    await getdetails();
  }

  async function gettokenPrice() {
    try{
      let price_arr = [];
      if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
        syncEach(network,async function (items, next) {
        var web3 = new Web3(items.rpcUrls);
          if(items.name!="BNB"){
            let contract_bridge = new web3.eth.Contract(abi, items.bridgecontract );
            let balance = await contract_bridge.methods.balanceOf(localStorage.getItem("address")).call();
            price_arr.push(balance);
            next();
          }else{
            let contract_Token = new web3.eth.Contract(tokenabi, items.tokencontract );
            let balance = await contract_Token.methods.balanceOf(localStorage.getItem("address")).call();
            price_arr.push(balance);
            next();
          }
        },function (err, transformedItems) {
          settokenPrice(price_arr);
          console.log(price_arr,"=====price_arrprice_arr")
        })
      }
    }catch(err){
      console.log(err,"====errrrrrr")
    }
  }

  async function getbalance() {
    try{
      if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
        let _fromname = localStorage.getItem("network") ? localStorage.getItem("network") : "BNB";
        let selecteddetails = network.find((o) =>o.name == _fromname  ? o : "");
        let _toname = localStorage.getItem("tonetwork") ? localStorage.getItem("tonetwork") : "AVAX";
        let selectedtodetails = network.find((o) =>o.name == _toname  ? o : "");
        let web3 = new Web3(selecteddetails.rpcUrls);
        let contarct = localStorage.getItem("network") == "BNB" ? selecteddetails.tokencontract :  selecteddetails.bridgecontract;
        let abi0 = localStorage.getItem("network") == "BNB" ? tokenabi : abi;
        let contract_bridge = new web3.eth.Contract(abi0, contarct);
        let bal = await contract_bridge.methods.balanceOf(localStorage.getItem("address")).call();
        bal = bal / 1e18;
        setuserbalance(bal);
        var value1 = network.find((o) => (o.name == selectedtodetails.name ? o : ""));
        let web31 = new Web3(value1.rpcUrls);
        let abi1 = localStorage.getItem("tonetwork") == "BNB" ? tokenabi : abi;
        let contarct1 = localStorage.getItem("tonetwork") == "BNB" ? value1.tokencontract : value1.bridgecontract;
        let contract_bridge1 = new web31.eth.Contract(abi1, contarct1);
        let bal1 = await contract_bridge1.methods
          .balanceOf(localStorage.getItem("address"))
          .call();
        bal1 = bal1 / 1e18;
        setusertobalance(bal1);
      }else{

      }
    }catch(errs){
      console.log(errs,"====errrss")
    }
  }

  async function getnetwork() {
    try {
      var reduxVal = store.getState();
      var transfort =
        reduxVal &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection.web3 &&
        reduxVal.walletConnection.web3.key
          ? reduxVal.walletConnection.web3
          : {};
      console.log(transfort, "====100010001000=======");
      if (
        transfort &&
        transfort != null &&
        transfort != undefined &&
        transfort != ""
      ) {
        console.log("inside");
        var web3 = new Web3(transfort);
        var networkselected = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        let selectedid = network.find((o) =>o.chainid == networkselected  ? networkselected : 0);
        console.log(networkselected,"======networkselected",selectedid)
        if (networkselected == selectedid.chainid) {
          localStorage.setItem("address", result[0]);
          let selecteddetails = network.find((o) =>o.chainid == networkselected  ? o : "");
          localStorage.setItem("network", selecteddetails.name);
          setnetworkdetail(selecteddetails);
          setfromlogo(selecteddetails.image);
          setfromselected(selecteddetails.name);
          let _toname = localStorage.getItem("tonetwork") && localStorage.getItem("tonetwork")!=selecteddetails.name  ? localStorage.getItem("tonetwork") :localStorage.getItem("tonetwork")!="AVAX" ? "AVAX" : localStorage.getItem("tonetwork")!="BNB" && "BNB" ;
          let selectedtodetails = network.find((o) =>o.name == _toname  ? o : "");
          localStorage.setItem("tonetwork", _toname);
          settologo(selectedtodetails.image);
          settoselected(selectedtodetails.name);
          let contarct = localStorage.getItem("network") == "BNB" ? selecteddetails.tokencontract :  selecteddetails.bridgecontract;
          let abi0 = localStorage.getItem("network") == "BNB" ? tokenabi : abi;
          let contract_bridge = new web3.eth.Contract(abi0, contarct);
          let bal = await contract_bridge.methods.balanceOf(result[0]).call();
          bal = bal / 1e18;
          setuserbalance(bal);
          var value1 = network.find((o) => (o.name == selectedtodetails.name ? o : ""));
          let web31 = new Web3(value1.rpcUrls);
          let abi1 = localStorage.getItem("tonetwork") == "BNB" ? tokenabi : abi;
          let contarct1 = localStorage.getItem("tonetwork") == "BNB" ? value1.tokencontract : value1.bridgecontract;
          let contract_bridge1 = new web31.eth.Contract(abi1, contarct1);
          let bal1 = await contract_bridge1.methods
            .balanceOf(result[0])
            .call();
          bal1 = bal1 / 1e18;
          setusertobalance(bal1);
        } else {
          setfromlogo("bnb.png");
          settologo("aval.png");
          // setuserbalance(0);
          // setusertobalance(0);
          localStorage.clear();
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err,"===er=dffvce")
    }
  }

  async function getdetails() {
    await getnetwork();
  }

  window.addEventListener("load", async (event) => {
    event.preventDefault();
    var provider = window.ethereum;
    var web3 = new Web3(provider);
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(async () => {
          var result = await web3.eth.getAccounts();
          var setacc = result[0];
          setwalletaddress(result[0]);
          localStorage.setItem("bridge", "yes");
          localStorage.setItem("address", result[0]);
          window.location.reload();
        }, 1000);
      });

      window.ethereum.on("networkChanged", async function (networkId) {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if (networkId == networkdetail.networkVersion) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts();
            var setacc = result[0];
            setwalletaddress(result[0]);
            localStorage.setItem("bridge", "yes");
            localStorage.setItem("address", result[0]);
            window.location.reload();
          }, 1000);
        } else {
          localStorage.removeItem("bridge");
          localStorage.removeItem("address");
          window.location.reload();
        }
      });
    }
  });

  async function connect_Wallet() {
    disconnect();
  }


  const interchange = async (event) => {

    // localStorage.setItem("network", toselected);
    // localStorage.setItem("tonetwork",fromselected);
    let to = fromselected;
    let from = toselected;
    let _tologo = fromlogo;
    let _fromlogo = tologo;
    // setfromlogo(_fromlogo);
    // settologo(_tologo);
    // setfromselected(from);
    // settoselected(to);
    console.log(to,from,"lolo===",_tologo,_fromlogo)
    var value = network.find((o) =>
              o.name == from ? o : ""
            );
    if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
      switchNetwork(value.chainid);
    }
  };

  async function fromchange(chaged){
    if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
     switchNetwork(chaged.chainid);
    }
    // setfromselected(chaged.name);
    // setfromlogo(chaged.image);
    // localStorage.setItem("network", chaged.name);
  }

  async function tochange(chaged){
    window.$('#network_to_modal').modal('hide');
    localStorage.setItem("tonetwork",chaged.name);
    settoselected(chaged.name);
    settologo(chaged.image)
  }

  const amountchange = async (e) => {
    setamount(e.target.value);
  };

  async function swapcheck() {
    try {
      setprogress(true);
      var reduxVal = store.getState();
      var transfort =
        reduxVal &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection.web3 &&
        reduxVal.walletConnection.web3.key
          ? reduxVal.walletConnection.web3
          : {};
      console.log(transfort, "===========");
      if (
        transfort &&
        transfort != null &&
        transfort != undefined &&
        transfort != ""
      ) {
        console.log("inside");
        var web3 = new Web3(transfort);
        if (
          amount != null &&
          amount != undefined &&
          amount != "" &&
          parseFloat(amount) > 0
        ) {
          let _toname = localStorage.getItem("tonetwork") ? localStorage.getItem("tonetwork") : "AVAX";
          let selectedtodetails = network.find((o) =>o.name == _toname  ? o : "");
          let contarct = networkdetail.bridgecontract;
          let contract_bridge = new web3.eth.Contract(abi, contarct);
          let token_contract = new web3.eth.Contract(tokenabi,networkdetail.tokencontract);
          let sendval = networkdetail.payable;
          sendval = sendval * 1e18;
          sendval = await convert(sendval);
          let amt = parseFloat(amount) * 1e18;
          amt = await convert(amt);
          let approve = localStorage.getItem("network") == "BNB" ? await token_contract.methods.approve(networkdetail.bridgecontract,amt.toString()).send({from:localStorage.getItem("address")})  : 0;
          console.log(localStorage.getItem("address") , networkdetail.passchainid , localStorage.getItem("address"), amt.toString(),localStorage.getItem("address"),"0x0000000000000000000000000000000000000000","0x","====sfbusdygfeifyeghf9e78")
          await contract_bridge.methods
            .sendFrom(localStorage.getItem("address") , selectedtodetails.passchainid , localStorage.getItem("address"), amt.toString(),localStorage.getItem("address"),"0x0000000000000000000000000000000000000000","0x")
            .send({
              from: localStorage.getItem("address"),
              value: sendval.toString(),
            })
            .then(async (result) => {
              setprogress(false);
              toastAlert("success", "Successfully Done", "success");
              window.location.reload();
            });
        } else {
          setprogress(false);
          toastAlert(
            "error",
            "Amount must be valid and Greater than Zero",
            "error"
          );
          return false;
        }
      } else {
        setprogress(false);
        toastAlert("error", "Connect Wallet to proceed Further", "error");
        return false;
      }
    } catch (err) {
      console.log(err,"====errrr")
      setprogress(false);
    }
  }

  function makeid(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function empty(){

  }

  async function transfertoken() {
    try {
      setbnbprogress(true);
      var reduxVal = store.getState();
      var transfort =
        reduxVal &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection &&
        reduxVal.walletConnection.web3 &&
        reduxVal.walletConnection.web3.key
          ? reduxVal.walletConnection.web3
          : {};
      if (
        transfort &&
        transfort != null &&
        transfort != undefined &&
        transfort != ""
      ) {
        var web3 = new Web3(transfort);
        var networkselected = await web3.eth.net.getId();
        if (networkselected == config.defaultchainid) {
          let contract_transfer = new web3.eth.Contract(
            transferabi,
            config.transfertoken
          );
          await contract_transfer.methods
            .getBNBToken()
            .send({ from: localStorage.getItem("address") })
            .then(async (result) => {
              toastAlert("success", "Token Received Successfully", "success");
              window.location.reload();
            });
        } else {
          setbnbprogress(false);
          toastAlert(
            "error",
            "Switch to Binance Network and proceed further",
            "error"
          );
        }
      } else {
        setbnbprogress(false);
        toastAlert("error", "Connect Wallet to proceed Further", "error");
        return false;
      }
    } catch (err) {
      setbnbprogress(false);
    }
  }

  return (
    <div className="hide_bg_footer">
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 10,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      {/* <div className="page-header header-filter">
        <div className={classes.container}>
          <GridContainer className="mt-5 align-items-center">
            <GridItem sm={12} md={10} lg={10} className="m-auto text-center" data-aos="fade-up" data-aos-duration="2000">
              <img src={require("../assets/images/banner_logo.png")} alt="logo" className="img-fluid" />
              <h1>THE SMART WAY TO CROWDFUNDING</h1>
              <p>Our model allows everyone win and benefit instantly.</p>
            </GridItem>
          </GridContainer>
        </div>
      </div> */}
      <main className="header_collec_s">
        {/* <div className="page_header_celection">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center content_ee">
              <h2>Swap your assets with another</h2><p></p>
            </div>
          </div>
        </div> */}

        <div className="container content_contain_box">
          <Button
            className="primary blue_btn gradient-box "
            onClick={() => transfertoken()}
            disabled={bnbprogress}
          >
            Get Bnb Testnet Token
          </Button>
          <br></br>
          <GridContainer className="mt-5">
            <GridItem sm={12} md={6} lg={5} className="m-auto">
              <div
                className="liquidity_div"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className="whitebox swap_box">
                  <div className="trade_wrap_title">
                    <div>
                      <h2>EXCHANGE</h2>
                      <h5>Trade tokens in an instant</h5>
                    </div>
                  </div>
                  <div className="bridge_inp_panel">
                    <div className="input_panel">
                      <div className="d-flex justify-content-between align-items-center">
                        <label>Token</label>
                        <label>Network</label>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <input type="number" className="custom_inp" min="0" onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} onChange={amountchange} value={amount}></input> */}
                        <Button className="token_btn" data-toggle="modal" data-target="#token_modal">
                          <img
                            src={require("../assets/images/" + fromlogo)}
                            alt="Logo"
                            className="img-fluid"
                          />{" "}
                          {fromselected}
                        </Button>
                        <Button className="token_btn" data-toggle="modal" data-target="#network_from_modal">{fromselected}</Button>
                      </div>
                    </div>
                    <div className="input_panel">
                      <div className="d-flex justify-content-between align-items-center">
                        <label>&nbsp;</label>
                        {/*<label>Balance</label>*/}
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {/*<button>Max</button>*/}
                          <input
                            type="number"
                            className="custom_inp"
                            min="0"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", ","].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onChange={amountchange}
                            value={amount}
                          ></input>
                        </div>
                        {/*<label>0</label>*/}
                      </div>
                    </div>
                  </div>
                  <h5>User Balance : {userbalance}</h5>

                  <div className="text-center mt-3 mb-3">
                    <img
                      src={require("../assets/images/exchange_icon.png")}
                      alt="Logo"
                      className="img-fluid"
                      onClick={interchange}
                    />
                  </div>

                  <div className="bridge_inp_panel">
                    <div className="input_panel">
                      <div className="d-flex justify-content-between align-items-center">
                        <label>Token</label>
                        <label>Network</label>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">                        
                        <Button className="token_btn" data-toggle="modal" data-target="#token_modal">
                          <img
                            src={require("../assets/images/" + tologo)}
                            alt="Logo"
                            className="img-fluid"
                          />
                          {toselected}
                        </Button>
                        <Button className="token_btn" data-toggle="modal" data-target="#network_to_modal">{toselected}</Button>
                      </div>
                    </div>
                    <div className="input_panel">
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <label>&nbsp;</label>
                        <label>&nbsp;</label>
                      </div> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <div>                          
                          <input
                            type="number"
                            className="custom_inp"
                            min="0"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", ","].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onChange={amountchange}
                            value={amount}
                          ></input>
                        </div>
                        {/*<label>-</label>*/}
                      </div>
                    </div>
                  </div>
                  <h5>User Balance : {usertobalance}</h5>
                  <div className="equivalent_value mt-2">
                    <h5> With in 30 Second Token Transfer will done</h5>
                  </div>
                  <div className="text-center mt-4">
                    <Button
                      className="primary_btn blue_btn gradient-box mt-4"
                      onClick={() => swapcheck()}
                      disabled={progress}
                    >
                      {progress? "Processing" : "Bridge"}
                    </Button>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <section></section>
      </main>
      {/* <FooterHome />       */}

      {/*  Wallet Token Modal */}
      <WalletModal />

      <div
        class="modal fade"
        id="confirm_model"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_model"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Confirm
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
            </div>
            <div class="modal-body">
              <h1>
                {amount} {Destinationtoken}
              </h1>
              <div className="from_content_cls">
                <div className="amount_content">
                  <span>From</span>
                  <img
                    src={require("../assets/images/" + tologo)}
                    alt="Icon"
                    className="mr-3 bsc_logo"
                  />
                  <p>{fromselected} Network</p>
                </div>
                <div>
                  <i className="fas fa-chevron-right"></i>
                </div>
                <div className="amount_content">
                  <span>To</span>
                  <img
                    src={require("../assets/images/" + fromlogo)}
                    alt="Icon"
                    className="mr-3 bsc_logo"
                  />
                  <p>{toselected} Network</p>
                </div>
              </div>
              <div className="data_confirm">
                <div className="flex_78">
                  <span>Asset</span>
                  <span>
                    <img
                      src={require("../assets/images/" + tologo)}
                      alt="Icons"
                    />
                    {Destinationtoken}
                  </span>
                </div>
                <div className="flex_78">
                  <span>Destination</span>
                  <span className="for_flex">
                    <img
                      src={require("../assets/images/" + fromlogo)}
                      alt="Icons"
                    />
                    <span>{walletaddress}</span>
                  </span>
                </div>
                <div className="flex_78">
                  <span>Network Fee</span>
                  <span>
                    {0} % {Destinationtoken}{" "}
                  </span>
                </div>
                <div className="flex_78">
                  <span>You will receive</span>
                  <span>
                    <img
                      src={require("../assets/images/" + fromlogo)}
                      alt="Icons"
                    />{" "}
                    = {parseFloat(amount).toFixed(5)} {Destinationtoken}{" "}
                    <em className="BEB_text">{" " + toselected}</em>
                  </span>
                </div>
              </div>
              <div className="terms_model">
                <p>
                  1.1. only the first transfer will be monitored, please
                  initiate a single transfer.
                </p>
                <p>
                  1.2. network fee and the final price depend on market
                  conditions. the final rate may be different once the
                  transaction is completed
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <div className="text-center mt-2 w100">
                {progress == false ? (
                  <Button
                    className="primary_btn"
                    onClick={() => afterconfirm()}
                  >
                    confirm
                  </Button>
                ) : (
                  <Button className="primary_btn">
                    Processing... Please Wait
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Token List Modal */}
      <div className="modal fade primary_modal" id="token_modal" tabIndex="-1" role="dialog" aria-labelledby="token_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="token_modal_title">Select a token</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<input className="search_inp mb-3" placeholder="Search by name" type="text"></input>*/}
              {/* <h4>Token Name</h4> */}
              <ul className="select_token_list">
                {network && network.length>0 && (network).map((item,index)=>{
                  return(
                    <div onClick={localStorage.getItem("tonetwork") && localStorage.getItem("tonetwork")==item.name ?()=>empty() : ()=>fromchange(item)}>
                      <li><img src={require("../assets/images/cryptocoins/alwin.png")} alt="Icons" /><p>{item.chainname}</p>
                      </li>
                      <p style={{color:"black"}}>{(parseFloat(tokenPrice[index])/1e18).toFixed(6)}</p>
                      {localStorage.getItem("tonetwork") && localStorage.getItem("tonetwork")==item.name &&
                        <p style={{color:"black"}} >(Selected as Destination One)</p>
                      }
                    </div>
                    )
                })}               
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Network List Modal */}
      <div className="modal fade primary_modal" id="network_from_modal" tabIndex="-1" role="dialog" aria-labelledby="network_from_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="network_modal_title">Select a Network</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<input className="search_inp mb-3" placeholder="Search by name" type="text"></input>*/}
              {/* <h4>Token Name</h4> */}
              <ul className="select_token_list">
                {network && network.length>0 && (network).map((item)=>{
                  return(
                      <li onClick={localStorage.getItem("tonetwork") && localStorage.getItem("tonetwork")==item.name ?()=>empty() : ()=>fromchange(item)}><img src={require("../assets/images/"+item.image)} alt="Icons" /><p>{item.chainname}</p>
                      {localStorage.getItem("tonetwork") && localStorage.getItem("tonetwork")==item.name &&
                        <p>Selected as Destination One</p>
                      }
                      </li>
                    )
                })}
              </ul> 
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade primary_modal" id="network_to_modal" tabIndex="-1" role="dialog" aria-labelledby="network_to_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="network_modal_title">Select a Network</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/*<input className="search_inp mb-3" placeholder="Search by name" type="text"></input>*/}
              {/* <h4>Token Name</h4> */}
              <ul className="select_token_list">
                {network && network.length>0 && (network).map((item)=>{
                  return(
                      <li onClick={localStorage.getItem("network") && localStorage.getItem("network")==item.name ?()=>empty() : ()=>tochange(item)}><img src={require("../assets/images/"+item.image)} alt="Icons" /><p>{item.chainname}</p>
                      {localStorage.getItem("network") && localStorage.getItem("network")==item.name &&
                        <p>Selected as from network baseOne</p>
                      }
                      </li>
                    )
                })}
              </ul> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
