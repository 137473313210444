import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer} from "react-toastify";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc,bscTestnet} from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { Provider } from "react-redux";
import store from './store'
// pages for this product
import Exchange from "views/exchange";
import config from "./config/config";



const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [infuraProvider({
    apiKey: config.projectId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version:2
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});

ReactDOM.render(
    <Provider store={store}>
  <BrowserRouter basename="/tokenbridge">
    <ToastContainer/>
     <WagmiConfig config={wconfigInitial}>
    <Switch>    
      <Route path="/" component={Exchange} />     
    </Switch>
    </WagmiConfig>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
